.sidebar {
  background: cl(secondary);
  min-height: calc(100% - 5.625rem);
  margin: rem(50px 0 40px);
  box-shadow:rem(0 33px 43px cl(black, .31));
  border-radius: rem(0 0 0 50px);
  padding: rem(25px 0);
  color:cl(white);
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
  &-open {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:calc(100% - 2rem);
    margin: 0 1rem;
    background: cl(primary);
    color:cl(white);
    font-size: rem(18px);
    line-height: rem(48px);
    font-family: $font;
    font-weight: 700;
    border: rem(1px solid cl(primary));
    cursor: pointer;
    border-radius: rem(25px);
    letter-spacing: rem(.5px);
    padding: rem(0 35px);
    transition: all .2s linear;
    &:before {
      content: "Open Menu";
      display: block;
    }
    &--active {
      margin-bottom: 1rem;
      &:before {
        content: "Hide Menu";
        display: block;
      }
      i {
        transform: rotate(180deg);
      }
    }
    i {
      transition: all .2s linear;
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  &-menu {
    list-style: none;
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
    li {
      font-size: rem(16px);
      line-height: 1.8;
      margin-bottom: 1px;
      a {
        padding: rem(7px 20px 7px 36px);
        color:cl(white);
        display: block;
        position: relative;
        &:hover {
          text-decoration: none;
          background: cl(primary);
        }
        &.current {
          font-weight: 700;
          background: cl(primary);
        }
        &:before {
          content: "";
          position: absolute;
          left:rem(24px);
          top:rem(16px);
          width:rem(4px);
          height: rem(4px);
          background: cl(white);
          border-radius: 50%;
        }
      }
    }
  }
  &-icon {
    width:rem(95px);
    height: auto;
    margin: 0 0 1rem;
  }
  &-inner {
    padding: rem(10px 10px 10px 35px);
    line-height: 1.5;
  }
  &-contact {
    display: flex;
    dt {
      width:rem(35px);
      flex-shrink: 0;
    }
    dd {
      margin: 0;
    }
  }
}