.scroll {
  position: fixed;
  left:rem(10px);
  bottom: rem(10px);
  width:rem(100px);
  height: rem(100px);
  padding: rem(17px);
  z-index: 201;
  transition: all .5s linear;
  //&--higher {
  //  bottom: rem(125px);
  //  @include media-breakpoint-down(sm) {
  //    bottom: rem(65px);
  //  }
  //}
  @include media-breakpoint-down(lg) {
    left:1rem;
  }
  @include media-breakpoint-down(sm) {
    left:0.3rem;
    bottom: 0.3rem;
    width:5rem;
    height: 5rem;
    padding: 1rem;
  }
  &.active {
    @include media-breakpoint-up(sm) {
      bottom: rem(50px);
    }
    @include media-breakpoint-up(xl) {
      bottom: rem(192px);
    }
    .scroll-circle {
      i {
        transform: rotate(180deg);
      }
      .scroll-circle-link {
        display: block;
      }
    }
    .scroll-circle-progress-bar {
      @include media-breakpoint-down(lg) {
        background: cl(secondary);
      }
    }
  }
  &-text {
    width:100%;
    height: auto;
    position: absolute;
    top:0;
    left:0;
  }
  &-circle {
    width:100%;
    height: 100%;
    border-radius: 50%;
    border:2px solid cl(primary);
    background: cl(primary);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    position: relative;
    i {
      position: relative;
      transition: all .3s linear;
      z-index: 2;
    }
    &-progress {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height: 100%;
      z-index: 1;
      background: cl(secondary);
      &-bar {
        background: cl(primary);
        width:100%;
        height: 0%;
      }
    }
    &-link {
      position: absolute;
      width:100%;
      height: 100%;
      z-index: 3;
      display: none;
    }
  }
}